import React from 'react';

const Sfondo = ({ children }) => {
  return (
    <div className="alfredovasco--bg-blualfredo-800 alfredovasco--transition-all alfredovasco--min-h-screen">
      {children}
    </div>
  );
};

export default Sfondo;