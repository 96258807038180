import React, { useEffect, useState } from 'react';
import { API_URL } from '../utils/config';
import CardVideo from '../components/video/card';
import { Link } from 'react-router-dom';

const VideoPage = () => {
    const [videos, setVideos] = useState([]);

	useEffect(() => {
		fetch(API_URL+'/alfredovasco/v1/play?per_page=9&order=desc&orderby=date&tipologiaplay=3&_embed')
			.then(response => response.json())
			.then(data => setVideos(data));
	}, []);
    return (
        <section className="alfredovasco--py-6 alfredovasco--text-white alfredovasco--container" data-aos="fade-up">
            <p class="alfredovasco--container alfredovasco--text-center alfredovasco--font-bold alfredovasco--font-serif alfredovasco--text-2xl lg:alfredovasco--text-4xl alfredovasco--italic alfredovasco--mb-2">Gli ultimi video</p>
			<div className="[&_a]:alfredovasco--w-3/4* md:[&_a]:alfredovasco--w-full* alfredovasco--w-full alfredovasco--grid md:alfredovasco--grid-cols-2 lg:alfredovasco--grid-cols-3 *2xl:alfredovasco--grid-cols-4 alfredovasco--gap-2.5">
			{videos.map(video => (
                <CardVideo video={video} />
            ))}



			</div>
		</section>
    );
};

export default VideoPage;