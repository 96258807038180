import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/theme';
import Sfondo from './components/website/sfondo';
import VideoSinglePage from './pages/singles/video';
import Header from './components/website/header';
import Footer from './components/website/footer';
import Homepage from './pages/homepage';
import LibriPage from './pages/libri';
import VideoPage from './pages/video';
import NotiziaPage from './pages/singles/notizia';
import LibroPage from './pages/singles/libro';

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

function App() {
  const setFullHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--fullheightin', window.innerHeight + 'px');
  };
  useEffect(() => {
    setFullHeight();
    window.addEventListener('resize', setFullHeight);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Sfondo>
          <Header />
          <Routes>
            <Route path="/" exact element={<><Homepage /><ScrollToTop /></>} />
            {/*<Route path="/chi-sono" exact element={<><ChiSiamoPage /><ScrollToTop /></>} />*/}
            <Route path="/blog" exact element={<><ScrollToTop /></>} />
            <Route path="/blog/:slug" exact element={<><NotiziaPage /><ScrollToTop /></>} />
            <Route path="/libri" exact element={<><LibriPage /><ScrollToTop /></>} />
            <Route path="/libro/:slug" exact element={<><LibroPage /><ScrollToTop /></>} />
            <Route path="/video" exact element={<><VideoPage /><ScrollToTop /></>} />
            <Route path="/video/:slug" exact element={<><VideoSinglePage /><ScrollToTop /></>} />
            {/*<Route path="/contatti" exact element={<><ContattiPage /><ScrollToTop /></>} />
            <Route path="/:slug" exact element={<><Page /><ScrollToTop /></>} />*/}
            {/*<Route path="*" exact element={<><Pagina404 /><ScrollToTop /></>} />*/}
          </Routes>
          <Footer />
        </Sfondo>
      </Router>
    </ThemeProvider>
  );
}

export default App;
