import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

const CardVideo = ({ video }) => {
    return (
    	<Link to={`/video/${video.slug}`} className="alfredovasco--w-full alfredovasco--shrink-0 alfredovasco--block alfredovasco--group alfredovasco--relative alfredovasco--transition alfredovasco--ease-out active:alfredovasco--opacity-75 alfredovasco--overflow-hidden">
            <div className="alfredovasco--aspect-w-16 alfredovasco--aspect-h-9"><LazyLoadImage
              className="alfredovasco--w-full alfredovasco--h-full alfredovasco--transform alfredovasco--transition alfredovasco--ease-out group-hover:alfredovasco--border-4 group-hover:alfredovasco--border-giallino alfredovasco--object-cover alfredovasco--rounded"
              src={video.campi.immagine_card.url}
              alt={video.title.rendered}
            /><i className="fa-regular fa-circle-video fa-2x afredovasco--absolute alfredovasco--top-[unset] alfredovasco--bottom-5 alfredovasco--left-5 alfredovasco--right-[unset] alfredovasco--w-[auto] alfredovasco--h-[auto] group-hover:alfredovasco--text-giallino"></i></div>
            <div className="alfredovasco--py-1 alfredovasco--px-2 alfredovasco--text-lg alfredovasco--font-semibold alfredovasco--transition alfredovasco--ease-out group-hover:alfredovasco--text-giallino alfredovasco--font-serif" dangerouslySetInnerHTML={{__html: video.title.rendered}}></div>
        </Link>
      );
    }

export default CardVideo;