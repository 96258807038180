import React from "react";

const HeroHomepage = () => {
    return (
        <div class="alfredovasco--flex alfredovasco--flex-col alfredovasco--relative">
            <picture>
                <source media="(max-width: 767px)" srcSet="https://alfredovasco.ottomanelliandrea.it/wp-content/uploads/2024/01/53656e7a612d7469746f6c6f2d4c612d7475612d73746f726961.png" />
                <source media="(min-width: 768px)" srcSet="https://alfredovasco.ottomanelliandrea.it/wp-content/uploads/2024/01/50726f676574746f2d73656e7a612d7469746f6c6f2d3138.png" />
                <img className="alfredovasco--w-full alfredovasco--h-fulw alfredovasco--object-bottom alfredovasco--object-cover" loading="lazy" src="https://alfredovasco.ottomanelliandrea.it/wp-content/uploads/2024/01/53656e7a612d7469746f6c6f2d4c612d7475612d73746f726961.png" alt="Immagine" />
            </picture>
            <div class="alfredovasco--container alfredovasco--flex--column alfredovasco--text-white alfredovasco--absolute alfredovasco--top-[50px] lg:alfredovasco--top-[45%] alfredovasco--text-center lg:alfredovasco--text-left">
                <h1 class="alfredovasco--font-bold alfredovasco--font-display alfredovasco--text-5xl xl:alfredovasco--text-6xl lg:alfredovasco--pl-5">Alfredo Vasco</h1>
                <h3 class="alfredovasco--font-medium alfredovasco--font-display alfredovasco--text-2xl xl:alfredovasco--text-3xl lg:alfredovasco--pl-5">Attore, raccontastorie, ...</h3>
            </div>
        </div>
    );
};

export default HeroHomepage;