import React, { useEffect, useState } from 'react';
import { API_URL } from '../../utils/config';
import CardLibro from '../libri/card';

const LibriHomePage = () => {
    const [libri, setLibri] = useState([]);

	useEffect(() => {
		fetch(API_URL+'/alfredovasco/v1/libri?per_page=6&order=asc&orderby=title')
			.then(response => response.json())
			.then(data => setLibri(data));
	}, []);
    return (
        <section className="alfredovasco--py-6 alfredovasco--text-white alfredovasco--container" data-aos="fade-up">
            <p class="alfredovasco--container alfredovasco--text-center alfredovasco--font-bold alfredovasco--font-serif alfredovasco--text-2xl lg:alfredovasco--text-4xl alfredovasco--italic alfredovasco--mb-2">I miei libri</p>
			<div className="[&_a]:alfredovasco--w-3/4* md:[&_a]:alfredovasco--w-full* alfredovasco--w-full alfredovasco--grid md:alfredovasco--grid-cols-2 lg:alfredovasco--grid-cols-3 *2xl:alfredovasco--grid-cols-4">
			{libri.map(libro => (
                <CardLibro key={libro.id} libro={libro} />
            ))}
			</div>
		</section>
    );
};

export default LibriHomePage;