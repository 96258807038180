import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    
    return (
        <footer className="alfredovasco--bg-blualfredo-800 alfredovasco--text-white alfredovasco--text-center alfredovasco--py-8 alfredovasco--px-4 alfredovasco--mt-8">
            <div className="alfredovasco--flex alfredovasco--flex-col alfredovasco--items-center alfredovasco--justify-center alfredovasco--space-y-4">
                <div className="alfredovasco--flex alfredovasco--flex-col alfredovasco--items-center alfredovasco--space-y-2">
                    <div className="alfredovasco--text-2xl alfredovasco--font-bold alfredovasco--font-logo">Alfredo Vasco</div>
                    <div className="alfredovasco--text-xl">Tutti i diritti riservati</div>
                    <div className="alfredovasco--text-lg">powered by Andrea Ottomanelli</div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;