import React from "react";

const ChiSonoHomePage = () => {
    return (
        <div className="alfredovasco--bg-sfondo-800">
            <div class="alfredovasco--text-center alfredovasco--text-white alfredovasco--container lg:alfredovasco--px-[25%] alfredovasco--py-12">
                <p class="alfredovasco--font-bold alfredovasco--font-serif alfredovasco--text-2xl lg:alfredovasco--text-4xl alfredovasco--italic">Chi sono</p>
                <p class="alfredovasco--font-serif alfredovasco--text-xl lg:alfredovasco--text-3xl alfredovasco--italic">We make films & drama. Sometimes we win awards or cause controversies. After a long time, we love what we do since we started doing it and never looked back.Now flying the flag for thenext crop of storytellers.</p>
            </div>
        </div>
    );
}

export default ChiSonoHomePage;