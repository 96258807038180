import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

const CardLibro = ({ libro }) => {
    return (
    	<Link to={`/libro/${libro.slug}`} className="*alfredovasco--w-3/4 alfredovasco--w-full alfredovasco--snap-start alfredovasco--snap-always alfredovasco--shrink-0 alfredovasco--block alfredovasco--group alfredovasco--relative alfredovasco--transition alfredovasco--ease-out active:alfredovasco--opacity-75 alfredovasco--overflow-hidden">
            <LazyLoadImage
              className="alfredovasco--w-full alfredovasco--transform alfredovasco--transition alfredovasco--ease-out group-hover:alfredovasco--scale-110 alfredovasco--h-full lg:alfredovasco--h-[35vh] alfredovasco--object-cover"
              src={libro.campi.immagine_card.url}
              alt={libro.title.rendered}
            />
            <div className="alfredovasco--absolute alfredovasco--inset-0 alfredovasco--bg-black alfredovasco--bg-opacity-25 alfredovasco--transition alfredovasco--ease-out group-hover:alfredovasco--bg-opacity-0"></div>
            <div className="alfredovasco--p-4 alfredovasco--flex alfredovasco--items-center alfredovasco--justify-center alfredovasco--absolute alfredovasco--inset-0">
                <div className="alfredovasco--py-1 alfredovasco--px-3 alfredovasco--bg-gray-900 alfredovasco--bg-opacity-95 alfredovasco--rounded-3xl alfredovasco--text-lg alfredovasco--font-semibold alfredovasco--transition alfredovasco--ease-out group-hover:alfredovasco--text-white group-hover:alfredovasco--bg-gray-950 alfredovasco--font-serif" dangerouslySetInnerHTML={{__html: libro.title.rendered}}></div>
            </div>
        </Link>
      );
    }

export default CardLibro;