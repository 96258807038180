import React, { useEffect, useState } from 'react';
import { API_URL } from '../../utils/config';
import CardBlog from '../blog/card';
import { Link } from 'react-router-dom';
import { register } from 'swiper/element/bundle';
register();

const BlogHomePage = () => {
    const [blog, setBlog] = useState([]);

	useEffect(() => {
		fetch(API_URL+'/alfredovasco/v1/notizie?per_page=9&order=desc&orderby=date&_embed')
			.then(response => response.json())
			.then(data => setBlog(data));
	}, []);
    return (
        <section className="alfredovasco--py-6 alfredovasco--text-white alfredovasco--container" data-aos="fade-up">
            <p class="alfredovasco--container alfredovasco--text-center alfredovasco--font-bold alfredovasco--font-serif alfredovasco--text-2xl lg:alfredovasco--text-4xl alfredovasco--italic alfredovasco--mb-2">Ultimi racconti</p>
			<div className="[&_a]:alfredovasco--w-3/4* md:[&_a]:alfredovasco--w-full* alfredovasco--w-full alfredovasco--grid md:alfredovasco--grid-cols-2 lg:alfredovasco--grid-cols-3 *2xl:alfredovasco--grid-cols-4 alfredovasco--gap-2.5">
			{blog.map(articolo => (
                <CardBlog articolo={articolo} />
            ))}
			<Link to={`/blog/`} className="alfredovasco--w-full alfredovasco--shrink-0 alfredovasco--block alfredovasco--group alfredovasco--relative alfredovasco--transition alfredovasco--ease-out active:alfredovasco--opacity-75 alfredovasco--overflow-hidden">
				<div className="alfredovasco--aspect-w-16 alfredovasco--aspect-h-9 alfredovasco--bg-gray-500"><p className="alfredovasco--flex alfredovasco--items-center alfredovasco--justify-center alfredovasco--transition alfredovasco--ease-out group-hover:alfredovasco--border-4 group-hover:alfredovasco--border-giallino">ALTRI ARTICOLI</p></div>
			</Link>
			</div>
		</section>
    );
};

export default BlogHomePage;