import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { API_URL } from '../../utils/config';

const Header = () => {
	const [menuItems, setMenuItems] = useState([]);
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [isSearchOpen, setSearchOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	useEffect(() => {
		fetch(API_URL+'/alfredovasco/v1/menu/2')
			.then(response => response.json())
			.then(data => setMenuItems(data));
	}, []);
	const navigate = useNavigate();
	const toggleMenu = () => {
		setMenuOpen((prev) => !prev);
	};
	const toggleSearch = () => {
		setSearchOpen((prev) => !prev);
	};
	const toggleMenuLink = () => {
		setMenuOpen(false);
	}
	useEffect(() => {
		const body = document.body;
		if (isSearchOpen) {
		  body.style.overflow = 'hidden';
		} else {
		  body.style.overflow = 'visible';
		}
		return () => {
		  body.style.overflow = 'visible';
		};
	}, [isSearchOpen]);
	const fetchDataFromApi1 = async (query) => {
		const response = await fetch(API_URL+`alfredovasco/v1/cerca/?search_term=${query}&_embed`);
		const data = await response.json();
		return data;
	};

	const handleSearch = async (e) => {
		e.preventDefault();
		try {
			var apiResults = [];
			apiResults["sito"] = await fetchDataFromApi1(searchQuery);
			setSearchResults(apiResults);
		} catch (error) {
			console.error('Errore durante la ricerca:', error);
		}
	};

	return (
		<><header className="alfredovasco--sticky alfredovasco--top-0 alfredovasco--w-full alfredovasco--z-[500] alfredovasco--h-header alfredovasco--transition-all alfredovasco--bg-blualfredo-500" id="header">
			<div className="alfredovasco--container alfredovasco--flex alfredovasco--content-center alfredovasco--items-center alfredovasco--h-header">
				<div className="alfredovasco--w-full alfredovasco--flex alfredovasco--flex-row alfredovasco--justify-between alfredovasco--content-center lg:alfredovasco--items-center">
					<Link to={`/`} className="alfredovasco--inline-block alfredovasco--font-logo alfredovasco--text-3xl alfredovasco--font-extrabold alfredovasco--text-left alfredovasco--text-white alfredovasco--no-underline alfredovasco--bg-transparent alfredovasco--cursor-pointer focus:alfredovasco--no-underline alfredovasco--leading-none">
						<span>Alfredo Vasco</span>
					</Link>
					<div className="alfredovasco--flex alfredovasco--flex-row alfredovasco--justify-between alfredovasco--content-center lg:alfredovasco--items-center">
						<a className="lg:alfredovasco--hidden alfredovasco--text-xl alfredovasco--flex-none alfredovasco--text-white alfredovasco--transition-all alfredovasco--font-medium alfredovasco--inline-flex alfredovasco--items-center alfredovasco--mr-2" onClick={toggleSearch}>
							<i className={`fa-light *alfredovasco--ml-1 ${isSearchOpen ? 'fa-x' : 'fa-magnifying-glass'}`}></i>
						</a>
						<a className={`lg:alfredovasco--hidden alfredovasco--inline-flex alfredovasco--text-white alfredovasco--items-center alfredovasco--font-bold alfredovasco--transition-all ${isSearchOpen ? 'alfredovasco--hidden' : ''}`} id="iconmenu" onClick={toggleMenu}>{isMenuOpen ? 'CHIUDI' : 'MENU'}<i className={`fa-light alfredovasco--ml-1 ${isMenuOpen ? 'fa-x' : 'fa-grip-lines'}`}></i></a>
					</div>
					<div className={`alfredovasco--transition-all alfredovasco--bg-blualfredo-500 lg:alfredovasco--text-current alfredovasco--fixed alfredovasco--${isMenuOpen ? 'block' : 'hidden'} lg:alfredovasco--block lg:alfredovasco--static alfredovasco--w-full lg:alfredovasco--w-fit alfredovasco--h-fulw lg:alfredovasco--h-fit alfredovasco--top-header alfredovasco--left-0 alfredovasco--right-0 alfredovasco--bottom-0 alfredovasco--container lg:alfredovasco--p-0 lg:alfredovasco--m-0`}>
						<nav className={`alfredovasco--w-full alfredovasco--flex alfredovasco--flex-col lg:alfredovasco--flex-row lg:alfredovasco--gap-x-3.5 alfredovasco--gap-y-2.5 lg:alfredovasco--gap-y-0 alfredovasco--text-3xl lg:alfredovasco--text-lg lg:alfredovasco--items-center alfredovasco--uppercase`}>
							{menuItems.map(item => (
								<NavLink
									key={item.id}
									to={item.url}
									className={({ isActive }) => {
										const textClass = isActive ? "alfredovasco--text-2xl lg:alfredovasco--text-lg alfredovasco--flex-none alfredovasco--text-text alfredovasco--transition-all alfredovasco--font-bold" : "alfredovasco--text-2xl lg:alfredovasco--text-lg alfredovasco--flex-none alfredovasco--text-white alfredovasco--transition-all alfredovasco--font-medium";
										const hiddenClass = isSearchOpen ? "alfredovasco--hidden" : "";
										return `${textClass} ${hiddenClass}`;
									}}
									onClick={toggleMenuLink}
									>
									{item.etichetta}
									</NavLink>
							))}
							<a className="alfredovasco--hidden lg:alfredovasco--block alfredovasco--text-2xl lg:alfredovasco--text-lg alfredovasco--flex-none alfredovasco--text-white alfredovasco--transition-all alfredovasco--font-medium" onClick={toggleSearch}>
								<i className={`fa-light *alfredovasco--ml-1 ${isSearchOpen ? 'fa-x' : 'fa-magnifying-glass'}`}></i>
							</a>
							<div className="lg:alfredovasco--hidden alfredovasco--grow"></div>
						</nav>
					</div>
					<div className={`alfredovasco--transition-all alfredovasco--bg-yellow-50 lg:alfredovasco--text-current alfredovasco--fixed alfredovasco--${isSearchOpen ? 'block' : 'hidden'} alfredovasco--w-full alfredovasco--h-fulw alfredovasco--top-header alfredovasco--left-0 alfredovasco--right-0 alfredovasco--bottom-0 alfredovasco--overflow-y-auto`}>
						<div className="alfredovasco--container">
							<div className="alfredovasco--flex alfredovasco--justify-center alfredovasco--items-center alfredovasco--h-full">
								<form className="alfredovasco--w-full alfredovasco--flex alfredovasco--justify-center alfredovasco--items-center" onSubmit={handleSearch}>
									<input type="text" className="alfredovasco--w-full alfredovasco--py-3 alfredovasco--px-5 alfredovasco--text-2xl alfredovasco--font-medium alfredovasco--text-black alfredovasco--border-2 alfredovasco--border-black alfredovasco--rounded alfredovasco--transition-all -focus:alfredovasco--outline-none focus:alfredovasco--border-2 focus:alfredovasco--border-black alfredovasco--caret-black" placeholder="Cerca..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
									<a className="alfredovasco--text-2xl alfredovasco--flex-none alfredovasco--text-black alfredovasco--transition-all alfredovasco--font-medium alfredovasco--inline-flex alfredovasco--items-center alfredovasco--px-5 *alfredovasco--border-2 alfredovasco--border-black alfredovasco--rounded-r" onClick={handleSearch}>
										<i className={`fa-light *alfredovasco--ml-1 fa-magnifying-glass`}></i>
									</a>
								</form>
							</div>
								{searchResults["sito"] && (
								<div className="alfredovasco--results-container alfredovasco--pb-2.5">
										<div>
										<div className="alfredovasco--grid oratorio-grid-cols-1 md:alfredovasco--grid-cols-2 lg:alfredovasco--grid-cols-3 2xl:alfredovasco--grid-cols-4 alfredovasco--gap-2.5 alfredovasco--mt-2.5">
        									{searchResults["sito"].map((result, index) => {
												
												return null;
											})}
											</div>
										</div>
								</div>
								)}
						</div>
					</div>
				</div>
			</div>
		</header>
		</>
	);
}

export default Header;