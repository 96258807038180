import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../utils/config';
import { Helmet } from 'react-helmet';

const LibroPage = () => {
    const { slug } = useParams();
    const [contenuto, setContenuto] = useState(null);
    
    useEffect(() => {
        fetch(API_URL + '/alfredovasco/v1/libri/?slug=' + slug + '&_embed')
        .then(response => response.json())
        .then(data => {
            setContenuto(data[0]);
        });  
    }, [slug]);
    
    return (
        <div className="alfredovasco--text-white">
        <Helmet>
            <title>{contenuto && contenuto.title.rendered}</title>
        </Helmet>
        {contenuto && (
            <>
                <img className="alfredovasco--w-full alfredovasco--h-dvh alfredovasco--fixed alfredovasco--z-0 alfredovasco--object-cover" src={contenuto.campi.sfondo.url} alt={contenuto.title.rendered} />
                <div className="alfredovasco--bg-blualfredo-800 alfredovasco--rounded-t-xl alfredovasco--container alfredovasco--z-10 alfredovasco--py-6 alfredovasco--px-5 alfredovasco--relative alfredovasco--top-toplev alfredovasco--min-h-dvh">
                    <div class="alfredovasco--grid alfredovasco--grid-cols-1 md:alfredovasco--grid-cols-3 lg:alfredovasco--grid-cols-4 alfredovasco--gap-5">
                        <div class="alfredovasco--col-span-1">
                            <img className="alfredovasco--w-full alfredovasco--rounded-lg" src={contenuto.campi.copertina.url} alt={contenuto.title.rendered} />
                        </div>
                        <div class="md:alfredovasco--col-span-2 lg:alfredovasco--col-span-3">
                            <h1 className="alfredovasco--text-2xl md:alfredovasco--text-3xl lg:alfredovasco--text-4xl alfredovasco--font-bold alfredovasco--font-display alfredovasco--mb-[10px]" dangerouslySetInnerHTML={{__html: contenuto.title.rendered}}></h1>
                            <h2 className="alfredovasco--text-xl md:alfredovasco--text-2xl lg:alfredovasco--text-3xl alfredovasco--font-medium alfredovasco--font-display alfredovasco--italic alfredovasco--mb-[10px]" dangerouslySetInnerHTML={{__html: contenuto.campi.sottotitolo}}></h2>
                            <ul className="alfredovasco--mb-5 alfredovasco--text-lg">
                                <li><span className="alfredovasco--font-semibold">Autore:</span>{' '}{contenuto.campi.autori.map((autore) => autore.name).join(', ')}</li>
                                <li><span className="alfredovasco--font-semibold">Genere:</span>{' '}{contenuto.campi.generi.map((genere) => genere.name).join(', ')}</li>
                                <li><span className="alfredovasco--font-semibold">Editore:</span>{' '}{contenuto.campi.editore.map((editore) => editore.name).join(', ')}</li>
                                <li><span className="alfredovasco--font-semibold">Anno di pubblicazione:</span>{' '}{contenuto.campi.anno_pubblicazione}</li>
                                <li><span className="alfredovasco--font-semibold">Numero pagine:</span>{' '}{contenuto.campi.numero_pagine}</li>
                            </ul>
                            <div className="alfredovasco--prose alfredovasco--prose-invert prose-p:alfredovasco--font-serif alfredovasco--prose-blue alfredovasco--max-w-none md:alfredovasco--prose-lg prose-headings:alfredovasco--font-display prose-headings:alfredovasco--font-bold *prose-headings:alfredovasco--uppercase prose-headings:alfredovasco--mb-1" dangerouslySetInnerHTML={{__html: contenuto.campi.descrizione}} />
                            {contenuto.campi.negozi_fisici && (
                                <div className="alfredovasco--mt-5">
                                    <h3 className="alfredovasco--text-xl md:alfredovasco--text-2xl lg:alfredovasco--text-3xl alfredovasco--font-bold alfredovasco--font-display alfredovasco--mb-[10px]">Acquista nei negozi</h3>
                                    <ul className="alfredovasco--text-lg">
                                    {contenuto.campi.negozi_fisici.map((negozio) => (
                                        <li key={negozio.negozio.id}><a className="alfredovasco--font-semibold" href={negozio.negozio.url}>{negozio.negozio.title}</a></li>
                                    ))}
                                    <li><span className="alfredovasco--font-medium">...e nelle migliori librerie</span></li>
                                    </ul>
                                </div>
                            )}
                            {contenuto.campi.negozi_online && (
                                <div className="alfredovasco--mt-5">
                                    <h3 className="alfredovasco--text-xl md:alfredovasco--text-2xl lg:alfredovasco--text-3xl alfredovasco--font-bold alfredovasco--font-display alfredovasco--mb-[10px]">Acquista online</h3>
                                    <ul className="alfredovasco--text-lg">
                                    {contenuto.campi.negozi_online.map((negozio) => (
                                        <li key={negozio.negozio.id}><a className="alfredovasco--font-semibold" href={negozio.negozio.url}>{negozio.negozio.title}</a></li>
                                    ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )}
        </div>
    );
}

export default LibroPage;