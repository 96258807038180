import React from "react";
import HeroHomepage from "../components/homepage/hero";
import LineaCentraleVerticale from "../components/homepage/lineacentraleverticale";
import ChiSonoHomePage from "../components/homepage/chisono";
import LibriHomePage from "../components/homepage/libri";
import VideoHomePage from "../components/homepage/video";
import BlogHomePage from "../components/homepage/blog";

const Homepage = () => {
    return (
        <div>
            <HeroHomepage />
            <LineaCentraleVerticale />
            <ChiSonoHomePage />
            <LibriHomePage />
            <VideoHomePage />
            <BlogHomePage />
        </div>
    );
}

export default Homepage;