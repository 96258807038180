import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import videojs from 'video.js';
import "videojs-youtube";
//import "@lawchihon/videojs-dailymotion";
import VideoPlayer from '../../components/player/videoFullPage';
import { API_URL } from '../../utils/config';

const VideoSinglePage = () => {
  const { slug } = useParams();
  const [contenuto, setContenuto] = React.useState(null);
  const [source, setSource] = React.useState({});
  const playerRef = React.useRef(null);

  useEffect(() => {
    fetch(API_URL + '/alfredovasco/v1/play/?slug=' + slug + '&_embed')
      .then(response => response.json())
      .then(data => {
        setContenuto(data[0]);
      });  
  }, [contenuto]);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: false,
    "youtube": { "ytControls": 0, "iv_load_policy": 3, "autoplay": 1 }
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on('ready', () => {
      if (contenuto.campi.video.url_video.indexOf('youtube') !== -1) {
        player.src({
          src: contenuto.campi.video.url_video,
          type: 'video/youtube'
        });
      } else if (contenuto.campi.video.url_video.indexOf('dailymotion') !== -1) {
        player.src({
          src: contenuto.campi.video.url_video,
          type: 'video/dailymotion'
        });
      } else {
        player.src({
          src: contenuto.campi.video.url_video,
          type: 'video/mp4'
        });
      }
      
      player.play();
    });

    player.on('dispose', () => {
      //videojs.log('player will dispose');
    });
  };

  return (
    <div className="alfredovasco--inset-0 alfredovasco--w-full alfredovasco--h-dvh alfredovasco--fixed alfredovasco--z-[999] alfredovasco--bg-blualfredo-800">
      {contenuto && (<VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} contenuto={contenuto} />)}
    </div>
  );
}

export default VideoSinglePage;