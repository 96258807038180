import React from 'react';
import videojs from 'video.js';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@mui/material';
import 'video.js/dist/video-js.css';
import '../../styles/player.css';

export const VideoPlayerFullPage = (props) => {
  const navigate=useNavigate();
  const location = useLocation();
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const {options, onReady, contenuto} = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const goBackOrHome = (e) => {
    e.preventDefault();
    console.log(location.state);
    if (location.state && location.state.previousPath) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.setAttribute('disableRemotePlayback', 'true');
      videoElement.classList.add('vjs-big-play-centered');
      videoElement.classList.add('alfredovasco--w-full');
      videoElement.classList.add('alfredovasco--h-dvh');
      videoElement.classList.add('vjs-theme-alfredovasco');
      videoRef.current.appendChild(videoElement);

      var overlaymeta = document.createElement('div');
      overlaymeta.className = 'overlaymeta alfredovasco--flex alfredovasco--items-center alfredovasco--absolute alfredovasco--top-0 alfredovasco--left-0 alfredovasco--w-full alfredovasco--z-50 alfredovasco--pointer-events-njone alfredovasco--py-3 alfredovasco--px-6 alfredovasco--select-none';
      const element = (
        <>
          <a className="noi fa-solid fa-arrow-left fa-3x" onClick={goBackOrHome}></a>
          {contenuto.title && (<div className="alfredovasco--flex-grow alfredovasco--text-lg lg:alfredovasco--text-3xl alfredovasco--ml-2.5 alfredovasco--leading-none" dangerouslySetInnerHTML={{__html: contenuto.title.rendered}}></div>)}
          <a className="noi fa-regular fa-share-nodes fa-2x" onClick={handleClickOpen}></a>
        </>
      );
      ReactDOM.createRoot(overlaymeta).render(element);
      const player = playerRef.current = videojs(videoElement, options, () => {
        //videojs.log('player is ready');
        onReady && onReady(player);
      });
      player.el().appendChild(overlaymeta);
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <>
      <div data-vjs-player>
        <div ref={videoRef} />
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <i className='fa-solid fa-xmark'/>
        </IconButton>
        <DialogContent dividers>
          Qui tasti condivisione, da decidere come
        </DialogContent>
      </Dialog>
    </>
  );
}

export default VideoPlayerFullPage;