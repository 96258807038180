import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../utils/config';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import 'moment/locale/it';

const NotiziaPage = () => {
    const { slug } = useParams();
    const [contenuto, setContenuto] = React.useState(null);
    
    useEffect(() => {
        fetch(API_URL + '/alfredovasco/v1/notizie/?slug=' + slug + '&_embed')
        .then(response => response.json())
        .then(data => {
            setContenuto(data[0]);
        });  
    }, [slug]);
    
    return (
        <div className="alfredovasco--text-white">
        <Helmet>
            <title>{contenuto && contenuto.title.rendered}</title>
            <meta name="description" content={contenuto && contenuto.excerpt.rendered} />
        </Helmet>
        {contenuto && (
            <>
                <img className="alfredovasco--w-full alfredovasco--h-dvh alfredovasco--fixed alfredovasco--z-0 alfredovasco--object-cover" src={contenuto._embedded["wp:featuredmedia"][0].source_url} alt={contenuto.title.rendered} />
                <div className="alfredovasco--bg-blualfredo-800 alfredovasco--rounded-t-xl alfredovasco--container alfredovasco--z-10 alfredovasco--py-6 alfredovasco--px-5 alfredovasco--relative alfredovasco--top-toplev alfredovasco--min-h-dvh">
                    <h1 className="alfredovasco--text-2xl md:alfredovasco--text-3xl lg:alfredovasco--text-4xl alfredovasco--font-bold alfredovasco--font-display alfredovasco--mb-[10px]" dangerouslySetInnerHTML={{__html: contenuto.title.rendered}}></h1>
                    <p className="alfredovasco--text-lg md:alfredovasco--text-xl alfredovasco--font-medium alfredovasco--mb-5"><strong>{moment(contenuto["date"]).format('DD MMMM YYYY')}</strong>{' '}{moment(contenuto["date"]).format('HH:ss')}</p>
                    <div class="alfredovasco--prose alfredovasco--prose-invert prose-p:alfredovasco--font-serif alfredovasco--prose-blue alfredovasco--max-w-none md:alfredovasco--prose-lg prose-headings:alfredovasco--font-display prose-headings:alfredovasco--font-bold *prose-headings:alfredovasco--uppercase prose-headings:alfredovasco--mb-1" dangerouslySetInnerHTML={{__html: contenuto.content.rendered}} />
                </div>
            </>
        )}
        </div>
    );
}

export default NotiziaPage;